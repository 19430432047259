<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：发票开票2</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                            <el-col :sm="12" :md="6">
                                <el-form-item :label="'销售开单、开票日期:'" label-width="145px">
                                    <el-date-picker
                                            style=" margin-top: 8px; width: 200px"
                                            v-model="pickerDate"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            @change="isDataChanage = true,tablePage.currentPage = 1,searchWBInvoice2()"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="right">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="6">
                                <el-tooltip class="item" effect="dark" placement="bottom-end" :open-delay="750">
                                    <div slot="content">
                                        {{formData.company_name}}
                                    </div>
                                    <el-form-item :label="'开票单位:'">
                                        <el-select ref="company_uuid" v-model="formData.company_uuid"
                                                   style="width: 100%" multiple collapse-tags
                                                   :disabled="formData.is_cancel"
                                                   default-first-option remote :remote-method="$_searchCustomerList"
                                                   clearable placeholder="全部"
                                                   filterable size="mini"
                                                   @change="isDataChanage = true,companyNameChangeEvent()"
                                                   @keyup.native.enter="$_blurNext('company_uuid'),$_focusNext('invoice_money')">
                                            <el-option
                                                    v-for="item in this.$store.getters.getCustomerList"
                                                    :key="item.id"
                                                    :label="item.customer_name"
                                                    :value="item.company_uuid">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :sm="12" :md="1">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px;margin-left: 5px "
                                           :disabled="formData.is_cancel"
                                           type="primary" plain @click="tablePage.currentPage = 1,searchWBInvoice2()"
                                           size="mini">搜索
                                </el-button>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="合计应收金额:" label-width="140px">
                                    <el-input size="mini" :readonly="true"
                                              :value="formData.total_amount?formData.total_amount:0"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="开票金额:" label-width="100px">
                                    <vxe-input ref="invoice_money" size="mini" @change="getTotalBalanceMoney()"
                                               type="float" style="width: 100px"
                                               :controls="false" v-model="formData.invoice_money"
                                               @keyup.native.enter="$_blurNext('invoice_money'),$_focusNext('remark')">
                                        >
                                    </vxe-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="合计未开票金额:" label-width="140px">
                                    <el-input size="mini" :readonly="true"
                                              :value="this.$XEUtils.commafy(formData.total_balance_money ?formData.total_balance_money :0, { digits: 2})"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="合计已收:" label-width="100px">
                                    <el-input size="mini" :readonly="true"
                                              :value="this.$XEUtils.commafy(formData.payend_wb?formData.payend_wb:0, { digits: 2})"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="合计未收款:" label-width="100px">
                                    <el-input size="mini" :readonly="true"
                                              :value="this.$XEUtils.commafy(formData.balance?formData.balance:0, { digits: 2})"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-form-item label="合计已开发票:" label-width="100px">
                                    <el-input size="mini" :readonly="true"
                                              :value="this.$XEUtils.commafy(formData.invoice_money_total?formData.invoice_money_total:0, { digits: 2})"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="8">
                                <el-form-item label="发票备注:">
                                    <el-input ref="remark" v-model="formData.remark" size="mini"
                                              :disabled="formData.is_cancel"
                                              @change="isDataChanage = true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-button v-show="!is_invoice_bill" style=" margin-top: 8px;margin-bottom: 8px;margin-left: 8px "
                                           :disabled="invoiceSaveList.length == 0"
                                           type="success" @click="buildOrUpdateInvoice()"
                                           size="mini">生成发票
                                </el-button>
                                <el-button v-show="is_invoice_bill" style=" margin-top: 8px;margin-bottom: 8px;margin-left: 8px "
                                           type="success" @click="buildOrUpdateInvoice()"
                                           size="mini">更新发票
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px;margin-left: 8px "
                                           :disabled="!is_invoice_bill"
                                           type="danger" plain @click="deleteInvoice()"
                                           size="mini">删除发票
                                </el-button>
                            </el-col>
                            <el-col :sm="12" :md="24">
                                <el-form-item label="所选单号:" label-width="100px">
                                    <el-input ref="remark" :value="invoice_bill_id" size="mini"
                                              :readonly="true"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                :show-footer="true"
                                ref="xGrid"
                                align="right"
                                row-id="id"
                                :footer-method="footerMethod"
                                highlight-current-row
                                size="mini"
                                :height="(this.getViewHeight() - 220)+''"
                                :print-config="{}"
                                :data="tableDataMain"
                                :merge-cells="mergeCells"
                                :columns="tableDataColumn"
                                :mouse-config="{selected: true}"
                                :pager-config="tablePage"
                                :cell-class-name="cellClassName"
                                @cell-dblclick="cellClickEvent"
                                @cell-click="cellSelectEvent"
                                @page-change="handlePageChange"
                                :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <!-- 开票备注 -->
                            <template #invoice_remark_edit="{ row}">
                                <vxe-input v-model="row.invoice_remark_kd"
                                           @blur="remarkEvent(row)"
                                           :disabled="row.bill_type != '销售开单'"></vxe-input>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
                <vxe-modal v-model="showModel.isShow" width="600" :title="showModel.bill_id">
                    <template #default>
                        <vxe-grid
                                ref="modalGrid"
                                show-overflow
                                auto-resize
                                height="300"
                                size="mini"
                                highlight-current-row
                                :data="tableDataModel"
                                :columns="tableColumnModel">
                        </vxe-grid>
                    </template>
                </vxe-modal>

            </el-form>
        </el-main>
    </div>
</template>

<script>

    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            return {
                is_invoice_bill:false,
                showModel: {
                    isShow: false,//显示model层
                    bill_id: '',//显示model层
                },
                pageType: '发票开票',
                filterData: {},
                sortData: {},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 15), new Date()],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [date, date]);
                       }
                   }, {
                        text: '最近周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [date, new Date()]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }]
               },
                formData: {
                    bill_date: new Date(),//开单日期
                    company_name : [],//开票单位
                    company_uuid : [],//companyUUID
                    total_amount: 0,
                    payend_wb: 0,
                    total_balance_money : 0,
                    balance: 0,
                    invoice_money: 0,//开票金额
                    invoice_money_total: 0,//合计已开发票
                    remark: '',//开票备注
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [200, 500, 1000]
               },
                checkboxRowTableData: [],
                tableDataModel: [],
                tableColumnModel: [
                    {
                        field:'is_cancel', title: '状态', width: 80, formatter: ({cellValue}) => {
                            if (cellValue) {
                                return '已作废'
                           } else {
                                return '正常'
                           }
                       }
                   },
                    {field:'only_bill_id', title: '单号', width: 120},
                    {field:'total_amount', width: 150, title: '金额'},
                    {field:'registrar', width: 150, visible: true, title: '创建人'},
                    {field:'only_bill_date', width: 150, visible: true, title: '创建日期'},
                    {field:'last_name', width: 150, visible: true, title: '最后修改人'},
                    {field:'last_date', width: 150, visible: true, title: '最后修改日期'},
                ],
                tableDataMain: [],
                invoiceSaveList: [],//开票的数组
                invoice_bill_id: '',//开票的ID用来绑定数组
                mergeCells: [],
                tableDataColumn: [
                    {type: 'checkbox', visible: false, width: 60},
                    {type: 'seq', title: '序号', visible: false, width: 40},
                    {
                        field:'only_bill_id', width: 120, title: '销售单据编号',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'cash_type_id', width: 55, title: '单号',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'only_bill_date', width: 90, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'bill_type', width: 70, title: '类型',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'subject_name_cash_data', width: 170, title: '科目',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill', width: 50, title: '税', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)) {
                                return ''
                           } else {
                                return cellValue + '%'
                           }
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'remark_warehouse_bill', width: 70, title: '备注',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'total_amount',
                        width: 80,
                        title: '应收金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'payend_wb',
                        width: 80,
                        title: '已收',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'balance',
                        width: 80,
                        title: '未收款',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'invoice_money',
                        width: 80,
                        title: '已开发票',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'sales_man',
                        width: 55,
                        title: '业务',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill2', width: 55, title: '开票', formatter: ({cellValue}) => {
                            if (cellValue == '否') {
                                return ''
                           }else{
                                return cellValue
                           }
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'has_invoice', width: 66, title: '已开票', formatter: ({cellValue}) => {
                            if (cellValue == '已开') {
                                return '✔';
                           }
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'invoice_remark_kd',
                        title: '单据备注',
                        editRender: {autofocus: 'input.vxe-input--inner'},
                        slots: {
                            edit: 'invoice_remark_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                    {
                        field:'invoice_remark',
                        title: '发票单备注',
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/invoice/searchHeard2', this.pickerDate, this.formData)
                       }
                   },
                ],
           }
       },
        methods: {
            remarkEvent(row) {
                this.$axios({
                    method: 'post',
                    url: '/admin/invoice/changeInvoiceRemarkKD',
                    data: {
                        only_bill_id: row.only_bill_id,
                        invoice_remark_kd: row.invoice_remark_kd,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                       });
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            //选中一行
            cellSelectEvent({row, rowIndex}) {
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                    this.$refs.xGrid.setCurrentRow(null);
               } else {
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
               }

                //this.$refs.xGrid.setCurrentRow(row);
                //console.log(this.$refs.xGrid.getCurrentRecord())
                this.sumCheckBoxRows();
                this.getTotalBalanceMoney();
                this.getSelectRecords();
                if (this.$refs.xGrid.getCurrentRecord() != null && this.$refs.xGrid.getCurrentRecord() != 'undefined' && this.$refs.xGrid.getCurrentRecord().bill_type == '发票单'){
                    console.log("进入发票单")
                    let row = this.$refs.xGrid.getCurrentRecord();
                    this.is_invoice_bill = true;
                    this.invoice_bill_id = row.cash_type_id;
                    this.formData.remark = row.invoice_remark;
                    this.formData.total_amount = row.total_amount;
                    this.formData.invoice_money = row.invoice_money;
                    this.formData.total_balance_money = row.total_balance_money;
               }else{
                    //console.log("没有进入发票单")
                    this.invoice_bill_id = this.invoiceSaveList.toString();
                    this.is_invoice_bill = false;
                    this.formData.remark = '';
                    this.formData.invoice_money = '';
               }
           },
            //选中合计
            sumCheckBoxRows() {
                this.formData.total_amount = 0;
                this.formData.payend_wb = 0;
                this.formData.balance = 0;
                this.formData.invoice_money_total = 0;
                let checkBoxRows = this.$refs.xGrid.getCheckboxRecords(true);
                //console.log(this.$refs.xGrid.getCurrentRecord())
                //console.log(this.is_invoice_bill)
                for (let i = 0; i < checkBoxRows.length; i++) {
                    let row = checkBoxRows[i];
                    if (this.isNumberVue(row.total_amount)) {
                        this.formData.total_amount = this.$XEUtils.add(this.formData.total_amount, row.total_amount);
                   }
                    if (this.isNumberVue(row.payend_wb)) {
                        this.formData.payend_wb = this.$XEUtils.add(this.formData.payend_wb, row.payend_wb);
                   }
                    if (this.isNumberVue(row.balance)) {
                        this.formData.balance = this.$XEUtils.add(this.formData.balance, row.balance);
                   }
                    if (this.isNumberVue(row.invoice_money)) {
                        this.formData.invoice_money_total = this.$XEUtils.add(this.formData.invoice_money_total, row.invoice_money);
                   }
               }
           },
            //双击勾单
            cellClickEvent({row, column}) {
                if (column.property == 'only_bill_id' && (row.bill_type == '销售开单' )) {
                    this.$_openType({path: '/transferSlip', query: {bill_id_kd : row.only_bill_id}})
               } else if ((row.bill_type == '发票单' && column.property == 'cash_type_id') || (row.bill_type == '销售开单' && column.property == 'cash_type_id' && !this.isBlankVue(row.cash_type_id))) {
                    this.showModel.isShow = true;
                    if (row.bill_type == '发票单'){
                        this.showModel.bill_id = row.cash_type_id;
                   }else{
                        this.showModel.bill_id = row.only_bill_id;
                   }
                    //查询单价
                    this.searchCashTypeId();
               } else if (row.bill_type == '收款单' && column.property == 'cash_type_id') {
                    this.$_openType({
                        path: '/receipt',
                        query: {cash_type_id: row.cash_type_id, year: row.year, month: row.month, type: row.cash_type}
                   });
               } else if (row.bill_type == '销售开单' && column.property == 'has_invoice') {
                    let b = true;
                    if (row.has_invoice == '已开'){
                        //已开，则取反值
                        b = false;
                   }
                    this.$axios({
                        method: 'post',
                        url: '/admin/invoice/hasInvoice',
                        data: {
                            only_bill_id: row.only_bill_id,
                            has_invoice: b
                       }
                   }).then((response) => {          //这里使用了ES6的语法
                        console.log(response)       //请求成功返回的数据
                        if (response.status === 200 && response.data.state == 'ok') {
                            this.$message({
                                showClose: true,
                                message: '设置成功！',
                                type: 'success'
                           });
                            this.searchWBInvoice2();
                       } else {
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            this.searchWBInvoice2();
                            return
                       }
                   }).catch((error) => {
                        console.log(error)
                   });
               }
           },
            cellClassName({row, column}) {
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)) {
                    return 'green-row';
               }
                if (row.bill_type == '收款单' && column.property == 'cash_type_id') {
                    return 'invoice-col-blue';
               } else if (row.bill_type == '收款单' && column.property != 'only_bill_id') {
                    return 'unEditor';
               } else if (row.bill_type == '销售开单' && (column.property == 'has_invoice' || column.property == 'invoice_remark_kd')) {
                    return 'col-fail';
               } else if (row.bill_type == '销售开单' && column.property == 'only_bill_id') {
                    return 'invoice-col-blue';
               } else if ((row.bill_type == '发票单' && column.property == 'cash_type_id') || (row.bill_type == '销售开单' && column.property == 'cash_type_id' && !this.isBlankVue(row.cash_type_id))) {
                    return 'col-success';
               } else if (row.bill_type == '发票单' && column.property != 'only_bill_id') {
                    return 'col-yellow';
               }
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.searchWBInvoice2()
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count ? this.$XEUtils.round(count, 2) : 0
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'total_amount' || column.property == 'payend_wb' || column.property == 'balance' || column.property == 'invoice_money') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

            //付款单位
            companyNameChangeEvent() {
                //console.log(item)
                /*this.filterData = {
                    companyUUID : item.companyUUID
               }*/
                this.formData.company_name = [];//item.customer_name
                this.searchWBInvoice2().then(() => {
                    if (!this.isBlankVue(this.formData.id)) {
                        this.$refs.xGrid.setCheckboxRow(this.checkboxRowTableData, true);
                   }
                    this.searchWBInvoice2();
               });
           },
            //计算冲账金额
            getTotalBalanceMoney() {
                this.formData.total_balance_money = this.$XEUtils.subtract(this.formData.total_amount, this.formData.invoice_money)
           },
            getSelectRecords() {
                this.invoiceSaveList = [];
                if (this.$refs.xGrid.getCheckboxRecords().length > 0) {
                    for (let i = 0; i < this.$refs.xGrid.getCheckboxRecords().length; i++) {
                        if ("销售开单" == this.$refs.xGrid.getCheckboxRecords()[i].bill_type) {
                            this.invoiceSaveList.push(this.$refs.xGrid.getCheckboxRecords()[i].only_bill_id);
                       }
                   }
               }
           },
            //删除发票
            deleteInvoice(){
                this.$XModal.confirm('您确定要删除：' + this.invoice_bill_id + '开单发票?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        this.getSelectRecords();
                        this.$axios({
                            method: 'post',
                            url: '/admin/invoice/deleteInvoice',
                            data: {
                                invoice_bill_id: this.invoice_bill_id,
                           }
                       }).then((response) => {//这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            //console.log('response', response)
                            //console.log('response.data.tableData',response.data.tableData)
                            if (response.status == 200 && response.data.state == "ok") {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                               });
                                this.searchWBInvoice2();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   }
               });
           },
            //生成发票
            async buildOrUpdateInvoice() {
                let message = '您确定要生成：' + this.invoice_bill_id + '销售开单发票?';
                if (this.is_invoice_bill){
                    message = '您确定要更新：' + this.invoice_bill_id + '发票单?';
               }
                this.$XModal.confirm(message).then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '饮茶先啦，数据查询中，请耐心等待!',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                       });
                        this.getSelectRecords();
                        this.$axios({
                            method: 'post',
                            url: '/admin/invoice/buildOrUpdateInvoice',
                            data: {
                                is_invoice_bill: this.is_invoice_bill,//是发票类型
                                invoice_bill_id: this.invoice_bill_id,
                                formData: this.formData,
                           }
                       }).then((response) => {//这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            //console.log('response', response)
                            //console.log('response.data.tableData',response.data.tableData)
                            loading.close();
                            if (response.status == 200 && response.data.state == "ok") {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'success'
                               });
                                this.searchWBInvoice2();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            loading.close();
                            console.log(error)
                       });
                   }
               });
           },
            searchCashTypeId(){
                this.$axios({
                    method: 'post',
                    url: '/admin/invoice/searchCashTypeId',
                    data: {
                        bill_id:this.showModel.bill_id,
                   }
               }).then((response) => {//这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log('response', response)
                    //console.log('response.data.tableData',response.data.tableData)
                    if (response.status == 200 && response.data.state == "ok") {
                        if (this.isBlankVue(response.data.list)) {
                            this.tableDataModel = [];
                       } else {
                            this.tableDataModel = response.data.list
                       }
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            async searchWBInvoice2() {
                //删除customerName
                delete this.filterData.customer_name
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                await this.$axios({
                    method: 'post',
                    url: '/admin/invoice/searchWBInvoice2',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        sortData: this.sortData,
                        isAccurate: true,
                        pickerDate: this.pickerDate,
                        formData: this.formData,
                   }
               }).then((response) => {//这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log('response', response)
                    //console.log('response.data.tableData',response.data.tableData)
                    loading.close();
                    if (response.status == 200 && response.data.state == "ok") {
                        if (response.data.page.list == null || response.data.page.list.length == 0) {
                            this.tableDataMain = [];
                            this.mergeCells = [];
                       } else {
                            this.tableDataMain = response.data.page.list
                            this.mergeCells = response.data.mergeCells
                       }
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        this.checkboxRowTableData = response.data.checkboxRowTableData
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
       }, computed: {
       },
        created() {
            /*this.formData.id = this.$route.query.id;
            if (this.isBlankVue(this.formData.id)) {
                this.formData.bill_date = new Date();
                this.$_searchCustomerList('');
           }*/
            this.$_searchCustomerList('');
            this.$_searchAccountList('');//经手人 全部用户
            this.searchWBInvoice2();
       }
   };


</script>

<style scoped>

    /deep/ table .el-input--mini .el-input__inner, table .el-input-number--mini {
        height: 28px;
        line-height: 28px;
   }

    /deep/ .el-input-group__prepend {
        border: initial;
   }

    /deep/ .vxe-body--row {
        font-size: 12px !important;
   }


</style>
